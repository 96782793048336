import { makeAutoObservable } from 'mobx';
import { AppState, AreaNames } from '../../app/AppState';

export class ImageState {
	public isActive: boolean = false;

	public constructor(
        private readonly appState: AppState,
        private readonly area: AreaNames,
    ) {
		makeAutoObservable(this);
	}

	public setActive = (): void => {
        if (this.isActive === false) {
            this.appState.getArea(this.area);
            this.isActive = true;
        } else if (this.isActive === true) {
            this.appState.getArea('');
            this.isActive = false;
        }
    };

	public onBlur = (): void => {
		this.isActive = false;
    };

    public get isSelected(): boolean {
        return this.appState.getMainSelectedSections.includes(this.area) || this.appState.getAllSelectedSections.includes(this.area);
    }
}