import { observer } from 'mobx-react';
import React from 'react';
import { AppState, AreaNames } from '../../app/AppState';
import { ImageState } from './ImageState';
import { Image } from './Image.style';

interface ImagePropsType {
    className?: string;
    area: AreaNames;
    src: any;
    appState: AppState;
}

export const ImageWrapper = observer(({className, area, src, appState }: ImagePropsType): JSX.Element | null => {
    const [imageState] = React.useState(() => new ImageState(appState, area));

    return <Image
        className={className}
        src={src}
        alt={area}
        tabIndex={0}
        onBlur={imageState.onBlur}
        onClick={imageState.setActive}
        isSelected={imageState.isSelected}
        isActive={imageState.isActive}
        isGrayscale={appState.isGrayscale}
    />
});
