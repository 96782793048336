import React from 'react';
import { observer } from 'mobx-react';
import { AppState } from '../app/AppState';
import { designsSet } from '../assets/desingsSets';
import { AppWrapper, WebsiteWrapper, FlexWrapper, HeaderWrapper, LeftMenu, Middle, RightMenu, FileData, AreaLabel, FilesList, FileLink, ListLabel } from '../app/App.style';
import { Header } from './header/Header';
import { ImageWrapper } from './image/Image';

import headerMobileImg from '.././assets/images/headerMobile.png';
import headerImg from '.././assets/images/header.png';

import searchBarHomePageImg from '.././assets/images/searchBarHomePage.png';
import menuImg from '.././assets/images/menu.png';

import promoCarouselImg from '.././assets/images/promoCarousel.png';
import nextOffImg from '.././assets/images/nextOff.png';
import homePageContentImg from '.././assets/images/homePageContent.png';
import footerImg from '.././assets/images/footer.png';

import betslipImg from '.././assets/images/betslip.png';

export const WebsiteView = observer((): JSX.Element => {
    const [appState] = React.useState(() => new AppState());

    return <AppWrapper>
        <Header appState={appState}/>

        <WebsiteWrapper>
                <FlexWrapper>
                    <div>
                        <HeaderWrapper>
                            <ImageWrapper src={headerImg} area='header' appState={appState} />
                        </HeaderWrapper>
                        <FlexWrapper>
                            <LeftMenu>
                                <ImageWrapper src={searchBarHomePageImg} area='searchBarHomePage' appState={appState} />
                                <ImageWrapper src={menuImg} area='menu' appState={appState} />
                            </LeftMenu>
                            <Middle>
                                <ImageWrapper src={promoCarouselImg} area='promoCarousel' appState={appState} />
                                <ImageWrapper src={nextOffImg} area='nextOff' appState={appState} />
                                <ImageWrapper src={homePageContentImg} area='homePageContent' appState={appState} />
                                <ImageWrapper src={footerImg} area='footer' appState={appState} />
                            </Middle>
                        </FlexWrapper>
                    </div>
                    <RightMenu>
                        <ImageWrapper src={headerMobileImg} area='headerMobile' appState={appState} />
                        <ImageWrapper src={betslipImg} area='betslip' appState={appState} />
                    </RightMenu>
                </FlexWrapper>
                <FileData>
                    {
                        appState.activeArea === '' ? null : <>
                            <AreaLabel>{designsSet(appState.activeArea).label}</AreaLabel>
                            <FilesList>
                                <ListLabel>Main design source:</ListLabel>
                                <FileLink>
                                    <a href={designsSet(appState.activeArea).filesList[0].url} target='_blank' rel='noreferrer'>
                                        {designsSet(appState.activeArea).filesList[0].name}
                                    </a>
                                </FileLink>
                                <>
                                    {designsSet(appState.activeArea).filesList.slice(1).length === 0 ? null : 
                                        <>
                                            <ListLabel>Other places:</ListLabel>
                                            <>
                                                {
                                                    designsSet(appState.activeArea).filesList.slice(1).map((file) => (
                                                        <FileLink key={file.url}><a href={file.url} target='_blank' rel='noreferrer'>{file.name}</a></FileLink>
                                                        ))
                                                    }
                                            </>
                                        </>
                                    }
                                </>
                            </FilesList>
                        </>
                    }
                </FileData>
        </WebsiteWrapper>
    </AppWrapper>
});
