import { makeAutoObservable } from 'mobx';
import { allFiles } from '../assets/allFiles';

export type AreaNames = 'searchBarHomePage' | 'menu' | 'promoCarousel' | 'homePageContent' | 'footer' | 'betslip' | 'header' | 'headerMobile' | 'nextOff';

interface FilesType {
    name: string;
    url: string;
    content: Array<string>;
    allContent: Array<string>;
}

export class AppState {
	public activeArea: AreaNames | '' = '';
    public isGrayscale: boolean = false;

	public selectValue: string = '';
	public isSelectionMain: boolean = false;
    public isSelectionAll: boolean = false;

	public fileData: FilesType | null = null;
    public activeFileUrl: string = '';
	public selectedSectionsMain: Array<string> = [];
	public selectedSectionsAll: Array<string> = [];

	public constructor() {
		makeAutoObservable(this);
	}

	public getArea = (area: AreaNames | ''): void => {
		this.activeArea = area;
	};

	public onSelectCB = (value: string): void => {
		this.selectValue = value;
		const fileData = allFiles.find(elem => elem.name === value);

		if (fileData !== undefined) {
			this.fileData = fileData;
		}

		this.onSelectMain();

		if (value === 'Select design file') {
			this.selectedSectionsMain = [];
			this.isGrayscale = false;
		}
	}

	public onSelectMain = (): void => {
		if (this.fileData?.content !== undefined) {
			this.selectedSectionsMain = this.fileData.content;
		}

		this.isGrayscale = true;
		this.isSelectionMain = true;
		this.isSelectionAll = false;

		this.selectedSectionsAll = []

		this.activeArea = '';
	};

	public onSelectAll = (): void => {
		if (this.fileData?.allContent !== undefined) {
			this.selectedSectionsAll = this.fileData.allContent;
		}

		this.isGrayscale = true;
		this.isSelectionMain = false;
		this.isSelectionAll = true;

		this.selectedSectionsMain = []

		this.activeArea = '';
	};

	public setIsGrayscale = (): void => {
		this.isGrayscale = !this.isGrayscale;
	}

	public get getIsSelectionMain(): boolean {
		return this.isSelectionMain;
	}

	public get getIsSelectionAll(): boolean {
		return this.isSelectionAll;
	}

	public get getMainSelectedSections(): Array<string> {
		return this.selectedSectionsMain;
	}

	public get getAllSelectedSections(): Array<string> {
		return this.selectedSectionsAll;
	}
}