import styled from '@emotion/styled';

export const HeaderWrapper = styled('div')`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    padding: 16px;
    background-color: #7b7ca7;
    margin-bottom: 16px;
`;

export const SelectWrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

export const Select = styled('select')`
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #7b7ca7;
    color: #000000;
    cursor: pointer;
    font-family: "Gill Sans", sans-serif;
    font-size: 16px;
    height: 40px;
    line-height: 1.1667;
    padding: 8px 4px;
    position: relative;
    width: 200px;
`;

export const Button = styled('button')`
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #616178;
    color: #18181d;
    font-family: "Gill Sans", sans-serif;
    font-size: 16px;
    height: 40px;
    line-height: 1.1667;
    padding: 8px;
    position: relative;
    cursor: pointer;

    &:hover {
        color: #ffffff;
        border-color: #ffffff;
    }
`;

export const RadioWrapper = styled('div')`
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
`

export const CheckInput = styled('input')`
    margin: 0 8px 0 0;
`

export const RadioLabel  = styled('label')<{isSelected: boolean}>`
    color: ${({ isSelected }): string => isSelected ? '#ffffff' : '#000000'};
    cursor: pointer;
    min-width: 150px;

    &:hover {
        color: #ffffff;
    }
`;
