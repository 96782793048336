import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';

interface ImagePropsType {
    isActive: boolean;
    isGrayscale: boolean;
    isSelected: boolean;
}

const setImageStyle = (isSelected: boolean, isActive: boolean, isGrayscale: boolean): SerializedStyles => {
    if (isActive) {
        return css`
            border: 2px solid #90ee90;
        `;
    }
    if (isSelected) {
        return css`
            border: 2px solid #ff0000;
        `;
    }

    if (isGrayscale === false) {
        return css`
            opacity: 1;
            filter: grayscale(0);
            border: 2px solid #808080;
        `;
    }

    return css`
        opacity: 0.2;
        filter: grayscale(1);
        border: 2px solid #808080;
    `;
};

export const Image = styled('img')<ImagePropsType>`
    ${({ isSelected, isActive, isGrayscale }): SerializedStyles => setImageStyle(isSelected, isActive, isGrayscale)};
    position: relative;
    width: 100%;

    &:hover {
        opacity: 1;
        filter: grayscale(0);
        border: 2px solid #ff9e08;
    }
`;
