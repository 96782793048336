import { AreaNames } from "../app/AppState";
import { betslip, footer, header, headerMobile, homePageContent, menu, nextOff, promoCarousel, searchBarHomePage } from '../assets/allFiles';

interface FilesType {
    label: string;
    filesList: Array<{
        name: string;
        url: string;
    }>
}

export const designsSet = (area: AreaNames): FilesType => {
    switch (area) {
        case 'searchBarHomePage': return {
            label: 'Search bar home page',
            filesList: searchBarHomePage,
        };
        case 'nextOff': return {
            label: 'Racing',
            filesList: nextOff,
        };
        case 'header': return {
            label: 'Header',
            filesList: header,
        };
        case 'headerMobile': return {
            label: 'Header Mobile',
            filesList: headerMobile,
        };
        case 'footer': return {
            label: 'Footer',
            filesList: footer,
        };
        case 'menu': return {
            label: 'Left menu',
            filesList: menu,
        };
        case 'promoCarousel': return {
            label: 'Promo Carousel',
            filesList: promoCarousel,
        };
        case 'homePageContent': return {
            label: 'Home Page Content',
            filesList: homePageContent,
        };
        case 'betslip': return {
            label: 'Betslip',
            filesList: betslip,
        };
    }
};
