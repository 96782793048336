import styled from '@emotion/styled';

export const AppWrapper = styled('div')`
    font-family: "Gill Sans", sans-serif;
`;

export const FlexWrapper = styled('div')`
    display: flex;
`;

export const WebsiteWrapper = styled('div')`
    display: flex;
    flex-flow: row nowrap;
`;

export const HeaderWrapper = styled('div')`
    max-width: 950px;
`;

export const LeftMenu = styled('div')`
    max-width: 115px;
`;

export const Middle = styled('div')`
    max-width: 835px;
`;

export const RightMenu = styled('div')`
    max-width: 165px;
`;

export const FileData = styled('div')`
    max-width: 250px;
    margin-left: 32px;
`;

export const AreaLabel = styled('span')`
    text-transform: uppercase;
    font-weight: 700;
`;

export const FilesList = styled('ul')`
    padding: 0;
`;

export const ListLabel = styled('span')`
    font-weight: 700;
`;

export const FileLink = styled('li')`
    margin: 8px 0;
`;
