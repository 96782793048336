export const allFiles = [
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=njnYO01ZLQ3TBjQA-0",
        "content": ["homePageContent", "promoCarousel"],
        "allContent": ["searchBarHomePage", "nextOff", "betslip", "footer", "menu", "header", "headerMobile", "promoCarousel", 'homePageContent']
    },
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
        "content": ["nextOff"],
        "allContent": ["searchBarHomePage", "nextOff", "footer", "menu", "header", "headerMobile"]
    },
    {
        "name": "Nav, footer & search",
        "url": "https://www.figma.com/file/JGhERm5vaDdzzK1SkbdjsU/Production-%2F-005-Navigation-Footer-and-Search-All-Universe?type=design&node-id=2117-72870&t=ucQs0v4YsacpGe9x-0",
        "content": ["footer", "searchBarHomePage", "menu", 'header', 'headerMobile'],
        "allContent": ["footer", "searchBarHomePage", "menu", 'header', 'headerMobile']
    },
    {
        "name": "Betslip",
        "url": "https://www.figma.com/file/DTL0CrxtZIZFKz07sjuj0K/Production-%2F-007-Betslip-All-Universe?type=design&node-id=0-1&t=Ytx0k3E3ma6agWZu-0",
        "content": ["betslip"],
        "allContent": ["betslip"]
    },
]

export const nextOff = [
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
    },
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=njnYO01ZLQ3TBjQA-0",
    },
]

export const header = [
    {
        "name": "Nav, footer & search --> Header Menu",
        "url": "https://www.figma.com/file/JGhERm5vaDdzzK1SkbdjsU/Production-%2F-005-Navigation-Footer-and-Search-All-Universe?type=design&node-id=2117-72870&t=397hIml8u6bVWwcj-0"
    },
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=xxIXYhmN1mfDBUxF-0"
    },
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
    },
]

export const headerMobile = [
    {
        "name": "Nav, footer & search --> RHS Menu",
        "url": "https://www.figma.com/file/JGhERm5vaDdzzK1SkbdjsU/Production-%2F-005-Navigation-Footer-and-Search-All-Universe?type=design&node-id=2117-72868&t=397hIml8u6bVWwcj-0"
    },
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=xxIXYhmN1mfDBUxF-0"
    },
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
    },
]

export const searchBarHomePage = [
    {
        "name": "Nav, footer & search --> Search",
        "url": "https://www.figma.com/file/JGhERm5vaDdzzK1SkbdjsU/Production-%2F-005-Navigation-Footer-and-Search-All-Universe?type=design&node-id=323-27747&t=397hIml8u6bVWwcj-0"
    },
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=xxIXYhmN1mfDBUxF-0"
    },
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
    },
]

export const menu = [
    {
        "name": "Nav, footer & search --> A-Z Menu",
        "url": "https://www.figma.com/file/JGhERm5vaDdzzK1SkbdjsU/Production-%2F-005-Navigation-Footer-and-Search-All-Universe?type=design&node-id=0-1&t=397hIml8u6bVWwcj-0"
    },
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=xxIXYhmN1mfDBUxF-0"
    },
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
    },
]

export const footer = [
    {
        "name": "Nav, footer & search --> Footer Menu",
        "url": "https://www.figma.com/file/JGhERm5vaDdzzK1SkbdjsU/Production-%2F-005-Navigation-Footer-and-Search-All-Universe?type=design&node-id=2117-72869&t=hnhKgR49UwtIGA2u-0"
    },
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=xxIXYhmN1mfDBUxF-0"
    },
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
    },
]

export const betslip = [
    {
        "name": "Betslip",
        "url": "https://www.figma.com/file/DTL0CrxtZIZFKz07sjuj0K/Production-%2F-007-Betslip-All-Universe?type=design&node-id=0-1&t=Ytx0k3E3ma6agWZu-0"
    },
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=xxIXYhmN1mfDBUxF-0"
    },
]

export const homePageContent = [
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=njnYO01ZLQ3TBjQA-0",
    },
    {
        "name": "Racing",
        "url": "https://www.figma.com/file/qaxwF5I12ObBajpCwT0aGW/Production-%2F-003-Racing-All-Universe?type=design&node-id=0-1&t=Y2rScl2uEcVqnTNx-0",
    },
]

export const promoCarousel = [
    {
        "name": "Home page",
        "url": "https://www.figma.com/file/CpdCig7oxRFKrFNG8o1myk/Production-%2F-001-Home-Page-All-Universe?type=design&node-id=937-24075&t=njnYO01ZLQ3TBjQA-0",
    },
]
