import React from 'react';
import { WebsiteView } from '../components/WebsiteView';
import { observer } from 'mobx-react';


export const App = observer((): JSX.Element => {
  return (
    <div className="App">
        <WebsiteView />
    </div>
  );
});