import { observer } from 'mobx-react';
import { allFiles } from '../../assets/allFiles';
import { AppState } from '../../app/AppState';
import { useState } from 'react';
import { makeAutoObservable } from 'mobx';
import { Button, HeaderWrapper, SelectWrapper, CheckInput, RadioLabel, RadioWrapper, Select } from './Header.style';

class SelectState {
    public eventValue: string = 'Select design file';

    constructor(
        private readonly appState: AppState,
    ) {
        makeAutoObservable(this);
    }

    onSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
            this.eventValue = event.target.value;

            this.appState.onSelectCB(this.eventValue);
    };

    onRadioSelectMain = (): void => {
        this.appState.onSelectMain();
    };

    onRadioSelectAll = (): void => {
        this.appState.onSelectAll();
    };
}

interface HeaderPropsType {
    appState: AppState;
}

export const Header = observer(({appState}: HeaderPropsType): JSX.Element => {
    const [selectState] = useState(() => new SelectState(appState));

    return <HeaderWrapper>
        <SelectWrapper>
            <Select onChange={selectState.onSelect}>
                {selectState.eventValue !== 'Select design file' ?
                    <option value='Select design file'>Reset</option> : <option value='Select design file'>Select design file</option>}
                {
                    allFiles.map((file) => (
                        <option key={file.name} value={file.name}>{file.name}</option>
                    ))
                }
            </Select>
            {selectState.eventValue === 'Select design file' ? null :
                <>
                    <a href={appState.fileData?.url} target='_blank' rel='noreferrer'>
                        <Button>Go to Figma</Button>
                    </a>
                    <RadioWrapper>
                        <RadioLabel isSelected={appState.getIsSelectionMain}>
                            <CheckInput type="radio" value="main" onChange={selectState.onRadioSelectMain} checked={appState.getIsSelectionMain} />
                            MAIN (file is main design source for marked components)
                        </RadioLabel>
                        <RadioLabel isSelected={appState.getIsSelectionAll}>
                            <CheckInput type="radio" value="all" onChange={selectState.onRadioSelectAll} checked={appState.getIsSelectionAll} />
                            ALL (marks all components present in design file)
                        </RadioLabel>
                    </RadioWrapper>
                </>
            }
        </SelectWrapper>
        <RadioLabel isSelected={appState.isGrayscale === false}>
            <CheckInput type='checkbox' onChange={appState.setIsGrayscale} checked={appState.isGrayscale === false}></CheckInput>
            Show full color
        </RadioLabel>
    </HeaderWrapper>
});
